<template>
  <div id="layout-wrapper" data-testid="main-wrapper">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />

    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/side-bar.vue';

export default {
  components: { NavBar, SideBar },
  data() {
    return {
      isMenuCondensed: false
    };
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 992) {
        router.afterEach(() => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });

        document.body.classList.toggle('vertical-collpsed');
      } else {
        router.afterEach(() => {
          document.body.classList.remove('sidebar-enable');
        });

        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    }
  }
};
</script>
